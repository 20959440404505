<template>
  <Listbox v-if="items.length" v-model="selectedItem">
    <div class="relative z-20 h-40">
      <ListboxButton
        class="absolute -z-10 flex h-40 justify-between border-b border-b-grey-mid text-grey-dark-02 focus-within:border-b-grey-dark-01 focus-within:text-black hover:border-b-grey-dark-02 hover:text-black"
        :class="widthClasses ? widthClasses : 'w-full'"
      >
        <p class="pr-4">{{ selectedItem?.label }}</p>
        <SpriteSymbol name="chevron-down" class="h-24 w-28" />
      </ListboxButton>
      <ListboxOptions
        class="dropdown shadow-md relative top-[39px] max-h-[360px] overflow-scroll border border-grey-light-01 bg-grey-light-03"
        :class="widthClasses ? widthClasses : 'w-full'"
      >
        <ListboxOption
          v-for="item in items"
          v-slot="{ selected, active }"
          :key="item.value"
          :value="item"
          :disabled="item.disabled"
        >
          <li
            class="z-50 flex h-64 cursor-pointer justify-between px-16 py-16 hover:bg-grey-light-01"
            :class="{
              'bg-grey-light-01': active,
              'bg-grey-light-03': !active,
            }"
          >
            <p class="pr-4">
              {{ item.label }}
            </p>
            <SpriteSymbol
              v-show="selected"
              name="check"
              class="mt-4 h-24 w-28 scale-110"
            />
          </li>
        </ListboxOption>
      </ListboxOptions>
    </div>
  </Listbox>
</template>

<script setup lang="ts">
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  provideUseId,
} from '@headlessui/vue'

export type DropdownItem = {
  value: string | number
  label: string
  disabled?: boolean
}

const props = defineProps<{
  items: DropdownItem[]
  widthClasses?: string
}>()

// fix hydration mismatch for headlessui https://github.com/tailwindlabs/headlessui/issues/2913#issuecomment-1924681165
provideUseId(() => useId())

const selectedValue = defineModel<string | number | undefined | null>()

const selectedItem = computed<DropdownItem>({
  get(): DropdownItem {
    const match = props.items.find((v) => v.value === selectedValue.value)
    if (match) {
      return match
    }
    return props.items[0]
  },
  set(item: DropdownItem) {
    selectedValue.value = item.value
  },
})
</script>

<style lang="scss" scoped>
.dropdown {
  scrollbar-color: black theme('colors.grey-light-02');
  scrollbar-width: thin;
  opacity: 1;
  overflow: auto;

  &::-webkit-scrollbar {
    opacity: 1;
    width: 4px;
  }

  &::-webkit-scrollbar:horizontal {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: block;
    background-color: black;
    border-radius: 0;
    width: 1px;
    margin-inline: 100px;
    padding: 20px;
  }

  &::-webkit-scrollbar-track {
    display: block;
    width: 100px;
    background-color: theme('colors.grey-light-02');
    border-radius: 0;
    margin-inline: 20px;
  }
}
</style>
